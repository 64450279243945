import React from "react";
import {
  HomeContainer,
  HomeInner,
  LeftColumn,
  Title,
  Copy,
  SmallCopy,
  LinkList,
  LinkListItem,
  ListLink,
  RouterListLink
} from "./styles";

const Home = ({ isPortfolio }) => {
  const linkList = [
    {
      name: "MFADT Thesis",
      url: "https://medium.com/@oror/watchthis-a-thesis-process-18973e03d32c",
    },
    {
      name: "LinkedIn",
      url: "http://www.linkedin.com/in/orleviteh",
    },
    {
      name: "DIY Blog",
      url: "http://or-leviteh.tumblr.com/",
    },
    {
      name: "Publications",
      url: "http://orleviteh.dropmark.com/163214",
    },
    {
      name: "Talented Friends",
      url: "http://orleviteh.dropmark.com/163217",
    },
  ];

  const designLink = {
    name: "Portfolio",
    url: "/design"
  };

  return (
    <HomeContainer>
      <HomeInner>
        <LeftColumn>
          <Title>Hi! I'm Or</Title>
          <Copy>
            I'm a UX Designer
            <br />
            with a background in Industrial Design,
            <br />
            Art, Video Editing, Sound Editing,
            <br />
            and a bunch of other stuff.
          </Copy>
          <Copy>
            Director of User Experience at
            <br />
            <a
              href="http://studiorodrigo.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Studio Rodrigo
            </a>
            , solving complicated
            <br />
            experience problems for big clients.
          </Copy>
          <SmallCopy>hi@orleviteh.com</SmallCopy>
        </LeftColumn>
        <div>
          <LinkList>
            {isPortfolio && (
              <LinkListItem key={designLink.name}>
                <RouterListLink to={designLink.url}>
                  {designLink.name}
                </RouterListLink>
              </LinkListItem>
            )}
            {linkList.map(link => (
              <LinkListItem key={link.name}>
                <ListLink
                  href={link.url}
                  target={link.target === "none" ? "" : "_blank"}
                  rel={link.rel === "none" ? "" : "noopener noreferrer"}
                >
                  {link.name}
                </ListLink>
              </LinkListItem>
            ))}
          </LinkList>
        </div>
      </HomeInner>
    </HomeContainer>
  );
};

export default Home;
