import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
  PortfolioContainer,
  SectionTitle,
  SectionTitleCopy,
  WorkContainer,
  WorkGallery,
  WorkGalleryItem,
  WorkGalleryImage,
  WorkImage,
  WorkItem,
  WorkTitle
} from "./styles";

const PortfolioSection = ({ categoriesArray, parentCategory }) => {
  const getMediumImage = image => {
    const mediumImage = image.mediaDetails.sizes.find(
      size => size.name === "medium"
    );
    return mediumImage
      ? mediumImage.sourceUrl
      : image.mediaDetails.sizes[0].sourceUrl;
  };

  const getSizeAdjust = ({ height, width }) => {
    const constantSize = 250;
    const newWidth = (width / height) * constantSize;
    return {
      growthRatio: newWidth / 250,
      heightPercent: (constantSize / newWidth) * 100,
      newWidth
    };
  };

  return (
    <PortfolioContainer>
      {categoriesArray.map(category => (
        <React.Fragment key={category.id}>
          {category.works.nodes.map((work, index) => {
            // const singleImage =
            //   work.image_type === "single"
            //     ? getMediumImage(work.homepage_image)
            //     : null;
            const gallery =
              work.image_type === "gallery" ? work.homepage_gallery : null;
            const { height, width } =
              work.image_type === "gallery"
                ? work.homepage_gallery[0].mediaDetails
                : work.homepage_image.mediaDetails;
            const additionalWidth = index === 0 && category.name ? 40 : 0;
            const { growthRatio, heightPercent, newWidth } = getSizeAdjust({
              height,
              width
            });
            return (
              <React.Fragment key={`${category.id}:${work.id}`}>
                <WorkItem
                  key={work.id}
                  growthRatio={growthRatio}
                  index={index}
                  width={newWidth + additionalWidth}
                >
                  {index === 0 && category.name && (
                    <SectionTitle>
                      <SectionTitleCopy>
                        {ReactHtmlParser(category.name)}
                      </SectionTitleCopy>
                    </SectionTitle>
                  )}
                  <WorkContainer isFirst={index === 0 && category.name}>
                    <Link to={`/${work.uri}`}>
                      <WorkTitle>{work.acf_title}</WorkTitle>
                      {work.image_type === "single" && (
                        <WorkImage
                          src={work.homepage_image.sourceUrl}
                          heightPercent={heightPercent}
                        />
                      )}
                      {gallery && (
                        <WorkImage
                          src={gallery[0].sourceUrl}
                          heightPercent={heightPercent}
                        />
                      )}
                      {gallery === "something" && (
                        <WorkGallery>
                          {gallery.map(galleryImage => (
                            <WorkGalleryItem key={galleryImage.id}>
                              <WorkGalleryImage
                                src={getMediumImage(galleryImage)}
                                alt={work.acf_title}
                              />
                            </WorkGalleryItem>
                          ))}
                        </WorkGallery>
                      )}
                    </Link>
                  </WorkContainer>
                </WorkItem>
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ))}
    </PortfolioContainer>
  );
};

export default PortfolioSection;
