import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-boost";
import { setContext } from "apollo-link-context";
import { withClientState } from "apollo-link-state";

const inMemoryCache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: []
      }
    }
  })
});

const stateLink = withClientState({
  cache: inMemoryCache
});

const authLink = setContext((_, { headers }) => {

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: "TkshBxaQYlAa:y3VVVkFf6T8z6il1qkkY5hZH6six8ZajpQV1wvKenBnu7iDy"
    }
  };
});

const link = new HttpLink({
  uri: "http://graphql.orleviteh.com/graphql"
});

const client = new ApolloClient({
  link: ApolloLink.from([stateLink, authLink, link]),
  cache: inMemoryCache
});

export default client;
