import React from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import {
  PortfolioContainer,
  WorkContainer,
  WorkImage,
  WorkItem,
  WorkTitle
} from "./styles";

const tumblrKey = "4RI6xV3RJVnSAuwdKBZDTQppjQrfcxGwtW1fymQzhAqnP70Lvk";
const blog = "or-leviteh.tumblr.com";

class Tumblr extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      fetched: 0,
      posts: [],
      hasMore: true
    };
  }

  componentDidMount() {
    this.fetchMore();
  }

  fetchMore() {
    const { posts, fetched } = this.state;
    axios
      .get(
        `https://api.tumblr.com/v2/blog/${blog}/posts/photo?api_key=${tumblrKey}&offset=${fetched}`
      )
      .catch(function(error) {
        // handle error
        console.log(error);
      })
      .then(res => {
        console.log(res);
        this.setState({
          fetched: fetched + res.data.response.posts.length,
          posts: [...posts, ...res.data.response.posts],
          hasMore: res.data.response.posts.length > 0
        });
      });
  }

  getSizeAdjust({ height, width }) {
    const constantSize = 250;
    const newWidth = (width / height) * constantSize;
    return {
      growthRatio: newWidth / 250,
      heightPercent: (constantSize / newWidth) * 100,
      newWidth
    };
  }

  render() {
    const { hasMore, posts } = this.state;
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.fetchMore.bind(this)}
        hasMore={hasMore}
        initialLoad={false}
        loader={<div key={0}>Loading ...</div>}
        useWindow={true}
      >
        <PortfolioContainer>
          {posts
            .filter(post => post.type === "photo")
            .map((post, index) => {
              const { height, width } = post.photos[0].original_size;
              const {
                growthRatio,
                heightPercent,
                newWidth
              } = this.getSizeAdjust({
                height,
                width
              });
              return (
                <React.Fragment key={`${post.id}`}>
                  <WorkItem
                    key={post.id}
                    growthRatio={growthRatio}
                    index={index}
                    width={newWidth}
                  >
                    <WorkContainer>
                      <a
                        href={post.short_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <WorkTitle>{post.blog_name}</WorkTitle>
                        <WorkImage
                          src={post.photos[0].original_size.url}
                          heightPercent={heightPercent}
                        />
                      </a>
                    </WorkContainer>
                  </WorkItem>
                </React.Fragment>
              );
            })}
        </PortfolioContainer>
      </InfiniteScroll>
    );
  }
}

export default Tumblr;
