import styled from "styled-components";
import { Link } from "react-router-dom";

export const HomeContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  height: 100vh;
  min-height: 540px;
  padding: 40px 16%;
  width: 100%;

  @media screen and (max-width: 1080px) {
    padding: 40px 5%;
  }

  @media screen and (max-width: 800px) {
    justify-content: center;
    min-height: 740px;
    padding: 40px 20px;
  }

  @media screen and (max-width: 600px) {
    min-height: 700px;
  }
`;

export const HomeInner = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 800px) {
    align-items: flex-start;
    flex-direction: column;
    width: auto;
  }
`;

export const LeftColumn = styled.div`
  margin-right: auto;
`;

export const Title = styled.h1`
  font-size: 70px;
  line-height: 70px;
  margin: 0 0 12px;

  @media screen and (max-width: 600px) {
    font-size: 50px;
    line-height: 50px;
    margin: 0 0 7px;
  }
`;

export const Copy = styled.p`
  font-size: 26px;
  line-height: 42px;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 38px;
    margin: 0;
  }
`;

export const SmallCopy = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin: 0;

  @media screen and (max-width: 800px) {
    margin-bottom: 45px;
  }
`;

export const LinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const LinkListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

export const ListLink = styled.a`
  border-bottom: 1px solid #bdbdbd;
  color: #5d5d5d;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  transition: border 200ms;

  &:hover {
    text-decoration: none;
    border-bottom-color: #5d5d5d;
  }
`;

export const RouterListLink = styled(Link)`
  border-bottom: 1px solid #bdbdbd;
  color: #5d5d5d;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  transition: border 200ms;

  &:hover {
    text-decoration: none;
    border-bottom-color: #5d5d5d;
  }
`;
