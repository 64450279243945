import styled from "styled-components";

const PortfolioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  * {
    box-sizing: border-box;
  }
`;

const WorkContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
`;

const WorkImage = styled.div`
  display: block;
  padding-top: ${({ heightPercent }) => heightPercent}%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  width: 100%;
`;

const WorkItem = styled.div`
  position: relative;
  display: flex;
  width: ${({ width }) => width}px;
  flex-grow: ${({ growthRatio }) => growthRatio};
  flex-basis: ${({ width }) => width}px;
  flex-shrink: ${({ growthRatio }) => growthRatio};

  &:last-child {
    max-width: 600px;
  }
`;

const WorkTitle = styled.h2`
  display: none;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: 0;
  margin: 0;
`;

export {
  PortfolioContainer,
  WorkContainer,
  WorkImage,
  WorkItem,
  WorkTitle
};
