import React, { Component } from "react";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import client from "../apollo-client";
import Home from "../Home";
import Portfolio from "../Portfolio";
import Work from "../Work";

class App extends Component {
  render() {
    const isPortfolio = parseInt(localStorage.getItem("isPortfolio"), 10) === 1;
    
    return (
      <Router>
        <ApolloProvider client={client}>
          <Switch>
            <Route
              exact={true}
              path="/"
              render={match => <Home isPortfolio={isPortfolio} />}
            />
            {isPortfolio && (
              <Route
                exact={true}
                path="/(art|design|stuff)"
                component={Portfolio}
              />
            )}
            {isPortfolio && (
              <Route exact={true} path="/:uri" component={Work} />
            )}
          </Switch>
        </ApolloProvider>
      </Router>
    );
  }
}

export default App;
