import gql from "graphql-tag";

const WORK_QUERY = gql`
  query workQuery($slug: String!) {
    workCategories(where: { slug: [$slug] }) {
      nodes {
        id
        name
        count
        children(first: 100 where: { orderby: DESCRIPTION }) {
          nodes {
            count
            id
            name
            works(first: 100) {
              nodes {
                acf_title
                homepage_gallery {
                  id
                  mediaDetails {
                    height
                    width
                    sizes {
                      name
                      sourceUrl
                    }
                  }
                  sourceUrl
                }
                homepage_image {
                  id
                  mediaDetails {
                    height
                    width
                    sizes {
                      name
                      sourceUrl
                    }
                  }
                  sourceUrl
                }
                id
                image_type
                uri
                width
              }
            }
          }
        }
      }
    }
  }
`;

export { WORK_QUERY };
