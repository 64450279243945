import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

const navLinkPositions = {
  left: css`
    font-size: 18px;
    left: 20px;
    transform: translate(0, -50%);
  `,
  center: css`
    font-size: 24px;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  offLeft: css`
    font-size: 18px;
    left: -50%;
    transform: translate(-50%, -50%);
  `,
  offRight: css`
    font-size: 18px;
    left: 150%;
    transform: translate(-50%, -50%);
  `,
  right: css`
    font-size: 18px;
    left: calc(100% - 20px);
    transform: translate(-100%, -50%);
  `
};

const CategoryContainer = styled.div`
  width: 100%;
  position: relative;
  overflow-x: hidden;
`;

const Header = styled.header`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e5e5e5;
  display: block;
  position: relative;
  background: white;
`;

const PortfolioCategory = styled.div`
  width: 100%;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
`;

const PortfolioContainer = styled.div`
  position: relative;
  display: flex;
  left: ${({ activeIndex }) => activeIndex * -100}vw;
  transition: left 350ms;
  width: 100%;
`;

const SectionLink = styled(NavLink)`
  color: #5d5d5d;
  font-family: Roboto;
  font-weight: 500;
  position: absolute;
  text-transform: capitalize;
  top: 50%;
  transition: all 350ms;

  ${({ activeindex, index }) => {
    if (activeindex === index) {
      return navLinkPositions.center;
    }
    if (activeindex - index === 1) {
      return navLinkPositions.left;
    }
    if (activeindex - index === -1) {
      return navLinkPositions.right;
    }
    if (activeindex - index === 2) {
      return navLinkPositions.offLeft;
    }
    if (activeindex - index === -2) {
      return navLinkPositions.offRight;
    }
  }}
`;

export {
  CategoryContainer,
  Header,
  PortfolioCategory,
  PortfolioContainer,
  SectionLink
};
