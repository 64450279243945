import React from "react";
import { Query } from "react-apollo";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { WORK_QUERY } from "./queries";
import PortfolioSection from "../PortfolioSection";
import Tumblr from "../Tumblr";
import {
  CategoryContainer,
  Header,
  PortfolioCategory,
  PortfolioContainer,
  SectionLink
} from "./styles";

const Portfolio = () => {
  const categories = ["art", "design", "stuff"];

  const activeIndex = ({ categories, path, prepend }) => {
    return categories.indexOf(path.replace(prepend, ""));
  };

  return (
    <CategoryContainer>
      <Route
        render={({ location }) => (
          <React.Fragment>
            <Header>
              {categories.map((cat, index) => (
                <SectionLink
                  activeindex={activeIndex({
                    categories,
                    path: location.pathname,
                    prepend: "/"
                  })}
                  index={index}
                  key={`key:${cat}`}
                  to={{ pathname: `/${cat}` }}
                >
                  {cat}
                </SectionLink>
              ))}
            </Header>
            <PortfolioContainer
              activeIndex={activeIndex({
                categories,
                path: location.pathname,
                prepend: "/"
              })}
            >
              {categories.map((cat, index) => (
                <Route
                  key={cat}
                  exact={true}
                  path={`/${cat}`}
                  children={({ match }) => {
                    return (
                      <CSSTransition
                        in={match ? true : false}
                        classNames="slide"
                        timeout={350}
                      >
                        {state => {
                          const visibleStates = [
                            "entering",
                            "exiting",
                            "entered"
                          ];
                          return (
                            <PortfolioCategory>
                              {visibleStates.indexOf(state) >= 0 && (
                                <React.Fragment>
                                  {cat !== "stuff" && (
                                    <Query
                                      query={WORK_QUERY}
                                      variables={{ slug: cat }}
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading) return `Loading ${cat}...`;
                                        if (error) {
                                          console.log(error);
                                          return `Error! ${error.message}`;
                                        }
                                        if (
                                          data.workCategories.nodes[0].children
                                            .nodes.length > 0
                                        ) {
                                          return (
                                            <PortfolioSection
                                              categoriesArray={
                                                data.workCategories.nodes[0]
                                                  .children.nodes
                                              }
                                              parentCategory={cat}
                                            />
                                          );
                                        } else {
                                          return "Nothing returned.";
                                        }
                                      }}
                                    </Query>
                                  )}
                                  {cat === "stuff" && <Tumblr/>}
                                </React.Fragment>
                              )}
                            </PortfolioCategory>
                          );
                        }}
                      </CSSTransition>
                    );
                  }}
                />
              ))}
            </PortfolioContainer>
          </React.Fragment>
        )}
      />
    </CategoryContainer>
  );
};

export default Portfolio;
