import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";

const WORK_QUERY = gql`
  query workQuery($uri: String!) {
    workBy(uri: $uri) {
      acf_content
      title: acf_title
      id
    }
  }
`;

const Work = ({ match }) => {
  const parseContent = acf_content => {
    return JSON.parse(acf_content);
  };

  return (
    <Query query={WORK_QUERY} variables={{ uri: match.params.uri }}>
      {({ data, error, loading }) =>  {
        if (loading) return "Loading work!";
        if (error) return "The work you're looking for doesn't exist!";

        if (data) {
          const content = parseContent(data.workBy.acf_content);
          console.log(content);

          return (
            <div>
              <h1>{data.workBy.title}</h1>
              {
                content.map((section, index) => (
                  <React.Fragment key={`${section.acf_fc_layout}:${index}`}>
                    {section.acf_fc_layout === "copy" && (
                      <div>
                        {ReactHtmlParser(section.copy)}
                      </div>
                      )}
                  </React.Fragment>
                ))
              }
            </div>
          );
        } else {
          return "Something went wrong :(";
        }
      }}
    </Query>
  );
};

export default Work;
